<ng-container *ngIf="IsNullOrUndefined(tenant)">
  <div class="loading-wrapper">
    <div class="container">
      <div>
        <!-- FORM -->
        <div class="form-content">
          <img class="hypecast-logo" src="../../assets/hypecast-logo.png"/>
          <h2 class="loading"><strong>Loading</strong></h2>
        </div>
        <div class="spinner">
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<router-outlet *ngIf="!IsNullOrUndefined(tenant)"></router-outlet>
