/**
 * Provides commonly used methods.
 *
 * @export
 */
export class __ {
  /**
   * Checks whether the passed argument is null or undefined, or not.
   *
   * @param argument The argument which should be checked for null or undefined.
   * @returns True, if the passed argument is null or undefined, false otherwise.
   * @example
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(null);
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(undefined);
   *
   * // Returns false
   * const result: boolean = __.IsNullOrUndefined(6);
   */
  static IsNullOrUndefined(argument: any) {
    return typeof argument === "undefined" || argument === null;
  }

  static IsNullOrUndefinedOrFalse(argument: any) {
    return typeof argument === "undefined" || argument === null || argument === false;
  }

  /**
   * Checks whether the passed argument is null, undefined or empty, or not.
   *
   * @param argument The argument which should be checked for null, undefined or empty.
   * @returns True, if the passed argument is null, undefined or empty, false otherwise.
   * @example
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(null);
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(undefined);
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined('');
   *
   * // Returns false
   * const result: boolean = __.IsNullOrUndefined('Notempty');
   */
  static IsNullOrUndefinedOrEmpty(argument: any) {
    return typeof argument === "undefined" || argument === null || argument === "" || argument.length === 0;
  }

  /**
   * Removes a provided item from a provided array based on the selector function. Optionally a function can be
   * provided that will be executed, if the provided item is not found in the provided array.
   *
   * @param array The array from which the item should be removed
   * @param item The item that should be removed from the array
   * @param selector The selector function that is being used to do the equality comparison
   * @param elseFunction An optional function that will be executed, if the item does not exist in the array
   * @returns The removed element, if the element is found an successfully removed. If an else function is provided, the return
   * value of that function will be returned. If none is provided, the return type is void
   */
  static RemoveFromArray<T>(array: T[], item: T, selector: (item: T) => any, elseFunction?: () => any): T | any | void {
    const _selector = __.IsNullOrUndefined(selector) ? (a: T) => a : selector;

    const index = array.findIndex((q) => _selector(item) === _selector(q));
    if (index > -1) {
      return array.splice(index, 1);
    } else {
      if (!__.IsNullOrUndefined(elseFunction)) {
        return elseFunction();
      }
    }
  }

  /**
   * Checks if a provided element is the last element of a provided array
   *
   * @param element The element which should be checked for the last position in the array
   * @param array The array which is being used to check if the element is the last element
   * @returns True if the provided element is the last in the provided array
   * @memberof __
   */
  static IsLastInArray(element: any, array: any[]): boolean {
    return array[array.length - 1] === element;
  }

  /**
   * Deep copies a passed object and returns it
   *
   * @export
   * @param from The object that should be deep copied
   * @returns The deep copy of the provided object
   */
  static Extend(from: any): any {
    if (from == null || typeof from != "object") return from;
    if (from.constructor != Object && from.constructor != Array) return from;
    if (
      from.constructor == Date ||
      from.constructor == RegExp ||
      from.constructor == Function ||
      from.constructor == String ||
      from.constructor == Number ||
      from.constructor == Boolean
    )
      return new from.constructor(from);
    let to = new from.constructor();
    for (let name in from) {
      to[name] = typeof to[name] == "undefined" ? __.Extend(from[name]) : to[name];
    }
    return to;
  }

  public static Random(min: number, max: number) {
    return Math.round(Math.random() * Math.abs(max - min) + min);
  }

  /**
   * Flattens a list in a list to a list.
   *
   * Use this function as the callbackFn for the array.reduce function.
   * @param a An element of the list
   * @param v An element of the list
   */
  static Flatten<T>(a: T[], v: T[]): T[] {
    return a.concat(v);
  }

  // __.Sort(this.invitedUsers, q => q.lastname, SortOrder.Ascending);
  static Sort<T>(entities: T[], prop: (c: T) => string, order: SortOrder): T[] {
    entities.sort((a, b) => {
      if (prop(a) < prop(b)) {
        return -1;
      }
      if (prop(a) > prop(b)) {
        return 1;
      }
      return 0;
    });

    if (order === SortOrder.Descending) {
      entities.reverse();
    }

    return entities;
  }

  static Dasherize(value: string): string {
    return value.replace(/[A-Z]/g, (char, index) => {
      return (index !== 0 ? "-" : "") + char.toLowerCase();
    });
  }

  static GroupBy<TValue, TKey extends string | number>(
    array: TValue[],
    accessor: (item: TValue) => TKey
  ): Group<TKey, TValue>[] {
    const result: { [key: string | number]: TValue[] } = {};

    for (const item of array) {
      if (__.IsNullOrUndefined(result[accessor(item) as any])) {
        result[accessor(item)] = [item];
      } else {
        result[accessor(item)].push(item);
      }
    }

    return Object.keys(result).map((q) =>
      Object.assign(new Group(), {
        key: q,
        values: result[q],
      } as Group<TKey, TValue>)
    );
  }
}

export class Group<TKey, TValue> {
  key: TKey;
  values: TValue[] = [];
}

export enum SortOrder {
  Ascending = "Ascending",
  Descending = "Descending",
}
